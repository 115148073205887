const logotext = "DP";
const meta = {
  title: "David Petri",
  description: "I'm David Petri, a technologist and machine learning enthuist.",
};

const introdata = {
  title: "Hello World, I'm David!",
  animated: {
    first: "M.Sc. Computer Science",
    second: "Software Engineer",
    third: "Machine Learning Engineer",
    // add more if you'd like but make sure to update /src/pages/home/index.js Line 29
  },
  description:
    "Dedicated technology enthusiast with an insatiable curiosity for unraveling the complexities of our digital world.",
  your_img_url: "https://i.ibb.co/wRjcnGL/me.jpg",
};

const dataabout = {
  title: "Who am I?",
  aboutme:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
};
const worktimeline = [
  {
    jobtitle: "Example 1",
    where: "Google",
    date: "2023",
  },
  {
    jobtitle: "Example 2",
    where: "Micosoft",
    date: "2022",
  },
  {
    jobtitle: "Example 3",
    where: "Meta",
    date: "2021",
  },
];

const skills = [
  {
    name: "Python",
  },

  {
    name: "C",
  },

  {
    name: "Java",
  },

  {
    name: "Bash",
  },

  {
    name: "Powershell",
  },

  {
    name: "Reverse Engineering",
  },

  {
    name: "Web-Exploitation",
  },

  {
    name: "Network Exploitation",
  },
];

const dataportfolio = [
  {
    img: "https://picsum.photos/400/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/800/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/600/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/300/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/700/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },

  {
    img: "https://picsum.photos/400/600/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/300/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/550/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
  {
    img: "https://picsum.photos/400/700/?grayscale",
    description:
      "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
  },
];

const contactConfig = {
  YOUR_EMAIL: "test@test.com",
  description: "Looking forward to hearing from you!",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "SERVICE_ID",
  YOUR_TEMPLATE_ID: "TEMPLATE_ID",
  YOUR_USER_ID: "USER_ID (PUBLIC_KEY)",
};

const socialprofils = {
  github: "https://github.com/",
  linkedin: "https://linkedin.com/",
};
export {
  meta,
  dataabout,
  dataportfolio,
  worktimeline,
  skills,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
